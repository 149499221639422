import * as React from 'react';

import { ThemeProvider } from '@emotion/react';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BankContext } from 'context/bankTransferContext';

const DetailOrder: React.FC = () => {
  let {
    handleSubmit,
    handleChange,
    btnTheme,
    isErrorGetDetail,
    VaNumber,
    vaname,
  } = React.useContext(BankContext);

  return (
    <div className="lg:h-[100vh]">
      <div className="flex items-center lg:pb-3 lg:mb-3">
        <ArrowBackIcon
          className="cursor-pointer"
          onClick={() => window.history.back()}
        />
        <div className="text-xl lg:text-2xl font-bold ml-3">{vaname}</div>
      </div>
      <br />

      <div className="bg-[#FFFFFF] px-5 py-5 lg:px-8 lg:py-3 rounded-md">
        <div className="font-bold text-lg py-3">Inquiry Request</div>
        <div className="lg:flex gap-20 justify-start  w-full mb-6 ">
          <div className="text-lg  text-left font-semibold items-center">
            Virtual Account Number
          </div>
          <div className="w-[50%] gap-2 flex flex-col">
            <input
              className="outline-none mt-2 lg:mt-0 bg-white w-full border-solid border-2 border-[#CBD5E1] border-inherit p-[10px] rounded-md"
              value={VaNumber}
              placeholder="Input here"
              onChange={handleChange}
            />

            {isErrorGetDetail && (
              <div className="text-[#EE4E2B] flex items-center">
                <InfoOutlinedIcon className="mr-2" />
                Virtual account number not found
              </div>
            )}

            <div className="items-end flex justify-end">
              <ThemeProvider theme={btnTheme}>
                <LoadingButton
                  variant="contained"
                  sx={{ height: 40, width: '111px' }}
                  color="primary"
                  loadingPosition="start"
                  loading={false}
                  onClick={handleSubmit}
                >
                  Submit
                </LoadingButton>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailOrder;
