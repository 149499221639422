import React from 'react';
import logo from 'assets/icons/logo.svg';
import { ThemeProvider } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { createTheme } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import config from 'constants/config';
import { FullVersion, isFullVersion } from 'utils/exceptions';
import { useNavigate } from 'react-router-dom';

const Navbar: React.FC = () => {
  const Navigate = useNavigate();

  const btnTheme = createTheme({
    palette: {
      primary: {
        main: '#21AF7D',
        darker: '#053e85',
        contrastText: '#fff',
      },
      secondary: {
        main: '#EBECED',
        contrastText: '#222222',
        darker: '#053e85',
      },
    },
  });

  const handleGoBack = () => {
    window.location.href = FullVersion
      ? config.MERCHANT_URL_FULL
      : config.MERCHANT_URL;
  };

  return (
    <div className="flex  text-[#231F20] items-center bg-[#FFFFFF] px-12 py-4  justify-between p-3">
      <div className="flex items-center">
        <div className="border-r-2 pr-5">
          <img src={logo} alt="logo" />
        </div>
        <div
          className="hidden lg:block text-sm w-full lg:text-2xl ml-4 font-bold cursor-pointer"
          onClick={() => Navigate(isFullVersion)}
        >
          QoinHub Simulator
        </div>
      </div>
      <ThemeProvider theme={btnTheme}>
        <LoadingButton
          variant="outlined"
          sx={{
            height: 40,
            width: '200px',
            fontWeight: 'bold',
          }}
          color="primary"
          loadingPosition="start"
          loading={false}
          startIcon={<HomeOutlinedIcon fontSize="small" />}
          onClick={handleGoBack}
        >
          <div className="text-[8px] lg:text-[13px]">Back to sandbox</div>
        </LoadingButton>
      </ThemeProvider>
    </div>
  );
};

export default Navbar;
