import QRISSimulator from "pages/simulator/qris";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores";
import {
  postInputUrlQris,
  getIssuerList,
  payQris,
  payQrisStatic,
  postQrisStatic,
} from "stores/actions/qrisAction";
import { qrisInputUrl, qrisPay } from "stores/types/qrisType";
import { IBodyData, IParams, IResponse } from "stores/types/simulatorType";

const useQrisSimulatorHooks = () => {
  const dispatch = useDispatch();
  const qrisSimulatorState = useSelector((state: RootState) => state.qris);

  const handlePostQris = useCallback(
    (data?: qrisInputUrl, callback?: (data: any) => void) => {
      dispatch(postInputUrlQris(data, callback));
    },
    [dispatch]
  );

  const handlePostPayment = useCallback(
    (bodyData: qrisPay, callback?: (data: IResponse) => void) => {
      dispatch(payQris(bodyData, callback));
    },
    [dispatch]
  );
  const handlePostQrisStatic = useCallback(
    (data?: qrisInputUrl, callback?: (data: any) => void) => {
      dispatch(postQrisStatic(data, callback));
    },
    [dispatch]
  );

  const handlePayQrisStatic = useCallback(
    (bodyData: qrisPay, callback?: (data: IResponse) => void) => {
      dispatch(payQrisStatic(bodyData, callback));
    },
    [dispatch]
  );

  const handleGetIssuerList = useCallback(
    (callback?: (data: IResponse) => void) => {
      dispatch(getIssuerList(callback));
    },
    [dispatch]
  );

  const handleSetToken = useCallback((token: string) => {
    // dispatch(setToken(token));
    // eslint-disable-next-line
  }, []);

  return {
    qrisSimulatorState,
    getIssuerList: handleGetIssuerList,
    postDetailQris: handlePostQris,
    postPayQris: handlePostPayment,
    postDetailQrisStatic: handlePostQrisStatic,
    postPayQrisStatic: handlePayQrisStatic,
  };
};

export default useQrisSimulatorHooks;
