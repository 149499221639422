import React from "react";

type PaymentInputProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PaymentInput: React.FC<PaymentInputProps> = ({ value, onChange }) => {
  return (
    <input
      className="outline-none bg-white w-[100%] border-solid border-2 border-[#CBD5E1] border-inherit p-[10px] rounded-md"
      defaultValue="Rp."
      placeholder="Input here"
      value={value}
      onChange={onChange}
    />
  );
};

export default PaymentInput;
