/* eslint-disable import/no-anonymous-default-export */

import { qrisAction, qrisState, qrisType } from "stores/types/qrisType";

const initialState: qrisState = {
  dataDetail: null,
  dataDetailStatic: null,
  error: null,
  errorPay: null,
  isLoading: false,
  isLoadingPay: false,
};

export default (
  state: qrisState = initialState,
  { type, payload }: qrisAction
) => {
  switch (type) {
    case qrisType.QRIS_PENDING:
      return { ...state, ...payload, error: null };
    case qrisType.QRIS_SUCCESS:
      return { ...state, ...payload, error: null, isLoading: false };
    case qrisType.QRIS_FAIL:
      return {
        ...state,
        ...payload,
        isLoading: false,
        dataDetail: null,
        dataDetailStatic: null,
      };
    case qrisType.QRIS_PAY_PENDING:
      return { ...state, ...payload, error: null };
    case qrisType.QRIS_PAY_SUCCESS:
      return {
        ...state,
        ...payload,
        error: null,
        isLoadingPay: false,
        dataDetail: null,
        dataDetailStatic: null,
      };
    case qrisType.QRIS_PAY_FAIL:
      return { ...state, ...payload, isLoadingPay: false };

    default:
      return state;
  }
};
