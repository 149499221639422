import * as React from "react";

import useSimulator from "hooks/useSimulator";
import { ThemeProvider } from "@emotion/react";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BankContext } from "context/bankTransferContext";
import PaymentInput from "components/paymentInput";
import ValueCard from "components/valueCard";
import SectionCard from "components/sectionCard";

const Component: React.FC = () => {
  let { simulatorState } = useSimulator();
  let {
    amount,
    orderNumber,
    status_text,
    customer_name,
    customer_email,
    customer_phone,
  } = simulatorState.data;

  let { btnTheme, handlePay, formattedAmount, handleChangeAmount } =
    React.useContext(BankContext);

  return (
    <div className=" lg:h-[100vh]">
      <div className="flex items-center lg:pb-3 lg:mb-3">
        <ArrowBackIcon
          className="cursor-pointer"
          onClick={() => window.history.back()}
        />
        <div className="text-xl lg:text-2xl font-bold ml-3">Detail Order</div>
      </div>
      <br />

      <div className="grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-2 ">
        {/* Order Summary */}
        <SectionCard label="Order Summary">
          <ValueCard label="Order Status" value={status_text} isStatus />
          <ValueCard label="Order Number" value={orderNumber} />
          <ValueCard label="Order Amount" value={amount} isAmount />
          <ValueCard
            label="Pay Amount"
            value={
              <PaymentInput
                value={formattedAmount || ""}
                onChange={handleChangeAmount}
              />
            }
          />
        </SectionCard>

        {/* customer detail */}
        <SectionCard label="Customer Detail">
          <ValueCard label="Name" value={customer_name} />
          <ValueCard label="Phone" value={customer_phone} />
          <ValueCard label="Email" value={customer_email} />
        </SectionCard>
      </div>
      <br />

      <div className="flex justify-end w-[100%]">
        <ThemeProvider theme={btnTheme}>
          <LoadingButton
            variant="contained"
            sx={{ height: 40, width: "182px" }}
            color="primary"
            loadingPosition="start"
            loading={false}
            onClick={
              status_text === "Paid" ? () => window.history.back() : handlePay
            }
          >
            {status_text === "Paid" ? "Back to home" : "Pay Now"}
          </LoadingButton>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Component;
