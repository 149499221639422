import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from 'constants/config';
import { FullVersion } from 'utils/exceptions';

const Component: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  let { orderNumber } = useParams();
  let signature = query.get('s');

  console.log(FullVersion);

  let urlWebview;

  FullVersion
    ? (urlWebview = config.WEBVIEW_URL_FULL)
    : (urlWebview = config.WEBVIEW_URL);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      // if (event.origin !== 'https://www.example.com') {
      //   return; // Pesan bukan dari URL yang diharapkan, lewati
      // }
      const message = event.data;
      if (message.type === 'redirect') {
        window.location.href = message.url;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <body style={{ margin: '0px', padding: '0px', overflow: 'hidden' }}>
        <iframe
          src={`${urlWebview}${orderNumber}?s=${signature}`}
          // src={`http://localhost:3000/sandbox/${orderNumber}?s=${signature}`}
          // src={`http://localhost:3000/redirect`}
          frameBorder="0"
          id="myIframe"
          style={{
            overflow: 'hidden',
            overflowX: 'hidden',
            overflowY: 'hidden',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
          }}
          title="webview"
          height="150%"
          width="150%"
        ></iframe>
      </body>
    </>
  );
};

export default Component;
