import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import {
  getDataVa,
  getPaymentList,
  postDataVa,
  setPageId,
  setToken,
  submidUid,
} from 'stores/actions/simulatorActions';
import { IBodyData, IParams, IResponse } from 'stores/types/simulatorType';

const useSimulator = () => {
  const dispatch = useDispatch();
  const simulatorState = useSelector((state: RootState) => state.simulator);

  const handleGetData = useCallback(
    (params: IParams, callback?: (data: IResponse) => void) => {
      dispatch(getDataVa(params, callback));
    },
    [dispatch]
  );

  const handleGetPaymentList = useCallback(
    (params, callback?: (data: IResponse) => void) => {
      dispatch(getPaymentList(params, callback));
    },
    [dispatch]
  );

  const handlePostData = useCallback(
    (bodyData: IBodyData, callback?: (data: IResponse) => void) => {
      dispatch(postDataVa(bodyData, callback));
    },
    [dispatch]
  );

  const handleSubmitUid = useCallback(
    (uid: string, callback?: (data: IResponse) => void) => {
      dispatch(submidUid(uid, callback));
    },
    [dispatch]
  );

  const handleSetPageId = useCallback(
    (id: number) => {
      dispatch(setPageId(id));
    },
    [dispatch]
  );

  const handleSetToken = useCallback((token: string) => {
    dispatch(setToken(token));
    // eslint-disable-next-line
  }, []);

  return {
    simulatorState,
    setToken: handleSetToken,
    getData: handleGetData,
    postData: handlePostData,
    getPaymentList: handleGetPaymentList,
    setPageId: handleSetPageId,
    submitUid: handleSubmitUid,
  };
};

export default useSimulator;
