import * as React from "react";

import { ThemeProvider } from "@emotion/react";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import { Box, Button, Modal, createTheme } from "@mui/material";
import useSimulator from "hooks/useSimulator";
import { IResponse } from "stores/types/simulatorType";
import Swal from "sweetalert2";
import sucessIcon from "assets/icons/succes.svg";

const EwalletDownload: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [uid, setUid] = React.useState("");
  const [uidSuccess, setUidSuccess] = React.useState(false);
  let { eWname } = useParams();
  let { simulatorState, submitUid } = useSimulator();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmitUid = () => {
    submitUid(uid, (response: IResponse) => {
      if (response.status_code !== 200) {
        Swal.fire({
          icon: "error",
          text: response?.message,
          timer: 1500,
          showConfirmButton: false,
        });
        handleClose();
        return;
      }
      console.log("asd");
      setUidSuccess(true);
    });
  };

  const btnTheme = createTheme({
    palette: {
      primary: {
        main: "#21AF7D",
        darker: "#053e85",
        contrastText: "#fff",
      },
      secondary: {
        main: "#EBECED",
        contrastText: "#222222",
        darker: "#053e85",
      },
    },
  });

  const redirectUrl = () => {
    window.location.href = simulatorState.pcUrl;
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    border: "none",
  };

  return (
    <div className=" h-[100vh]">
      <div className="flex items-center pb-3 mb-3">
        <ArrowBackIcon
          className="cursor-pointer"
          onClick={() => window.history.back()}
        />
        <div className="text-2xl font-bold ml-3">{eWname} Simulator</div>
      </div>
      <br />

      <div className="bg-[#FFFFFF] px-8 py-3 rounded-md">
        <div className="lg:w-[75%]">
          <div className="font-bold text-lg py-3">Download Simulator</div>
          <div className="flex items-center justify-start  w-full mb-6">
            <div className="text-lg text-left font-semibold items-center">
              To simulate the {eWname} payment channel, please use the{" "}
              {eWname + " "}
              APK simulator by clicking the download button below
            </div>
          </div>

          <br />

          {/* modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="rounded-md">
              {uidSuccess ? (
                <div className="flex justify-center items-center flex-col">
                  <img width={100} src={sucessIcon} alt="succes" />
                  <br />
                  <b>Permintaan Download Berhasil</b>
                  <p className="text-[14px] mt-1 text-center w-[90%]">
                    Permintaan Anda akan diproses. Silahkan cek email secara
                    berkala untuk mendapatkan URL App Center.
                  </p>
                  <br />
                  <ThemeProvider theme={btnTheme}>
                    <Button
                      variant="contained"
                      sx={{ height: 40 }}
                      className="w-full"
                      color="primary"
                      onClick={() => {
                        handleClose();
                        setUidSuccess(false);
                      }}
                    >
                      ok
                    </Button>
                  </ThemeProvider>
                </div>
              ) : (
                <div>
                  <b>Input Your UID</b>
                  <p className="mt-4">
                    To download the {eWname} payment channel, please input your
                    UID number.
                  </p>
                  <div className="flex my-5 items-center">
                    <b>UID No.</b>
                    <input
                      placeholder="Input UID here"
                      className="ml-4 w-[275px] h-[50px] px-3 border-2 rounded-md"
                      onChange={(e) => {
                        setUid(e.target.value);
                      }}
                    />
                  </div>
                  <ThemeProvider theme={btnTheme}>
                    <LoadingButton
                      variant="contained"
                      sx={{ height: 40 }}
                      className="w-full"
                      color="primary"
                      loadingPosition="start"
                      loading={false}
                      onClick={handleSubmitUid}
                    >
                      Confirm
                    </LoadingButton>
                  </ThemeProvider>
                </div>
              )}
            </Box>
          </Modal>

          <div className="block lg:flex justify-end ">
            <ThemeProvider theme={btnTheme}>
              {eWname !== "Netzme QRIS" && (
                <LoadingButton
                  variant="outlined"
                  sx={{
                    height: 40,
                    marginRight: 2,
                    marginBottom: 2,
                  }}
                  className="w-full lg:w-[250px]"
                  color="primary"
                  loadingPosition="start"
                  loading={false}
                  onClick={handleOpen}
                >
                  Download for IOS
                </LoadingButton>
              )}
              <LoadingButton
                variant="contained"
                sx={{ height: 40 }}
                className="w-full lg:w-[250px]"
                color="primary"
                loadingPosition="start"
                loading={false}
                onClick={() => redirectUrl()}
              >
                Download for Android
              </LoadingButton>
            </ThemeProvider>
          </div>
        </div>

        <br />
      </div>
    </div>
  );
};

export default EwalletDownload;
