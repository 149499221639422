import React from "react";

import InquiryRequest from "./inquiryRequest";
import Detail from "./detailOrder";

import BankProvider, { BankContext } from "context/bankTransferContext";

const Components: React.FC = () => {
  let { isDetail } = React.useContext(BankContext);
  return <>{isDetail ? <Detail /> : <InquiryRequest />}</>;
};

const bankTransfer = () => {
  return (
    <BankProvider>
      <Components />
    </BankProvider>
  );
};

export default bankTransfer;
