import React, { ReactNode } from "react";

interface Props {
  label?: string;
  children: ReactNode;
}

const Section: React.FC<Props> = ({ label, children }): JSX.Element => (
  <div className="bg-[#FFFFFF] px-8 py-3 rounded-md">
    {label && <div className="font-bold text-lg py-3">{label}</div>}
    <div className="flex flex-col">{children}</div>
  </div>
);

export default Section;
