import { NumericFormat } from "react-number-format";

interface OrderDetailProps {
  label: string;
  value: any;
  isStatus?: boolean;
  isAmount?: boolean;
}

const OrderDetail: React.FC<OrderDetailProps> = ({
  label,
  value,
  isStatus,
  isAmount,
}) => {
  const formattedValue = isAmount ? (
    <>
      Rp.
      <NumericFormat
        value={value}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"  "}
      />
    </>
  ) : (
    value
  );

  const statusClass = isStatus
    ? value === "Waiting Payment"
      ? "border-[#B4B4B4] w-[40%] lg:w-auto bg-[#EEEEEE] p-2 rounded-lg text-center px-3 text-[#5A5A5A]"
      : "border-[#1E9F72] w-[40%] lg:w-auto bg-[#B8DBCA] p-1 rounded-lg text-center px-3 text-[#1E9F72]"
    : "";

  return (
    <div>
      <div className="lg:flex text-md items-center my-2">
        <div className="font-bold w-full mb-2 lg:mb-0 lg:w-[35%]">{label}</div>
        <div className={statusClass}>{formattedValue}</div>
      </div>
    </div>
  );
};

export default OrderDetail;
