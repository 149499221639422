import { AxiosError } from "axios";
import provider, { IProvider } from "provider";
import MethodProvider from "provider/methods";
import { ThunkAction } from "redux-thunk";
import { RootState } from "stores";
import { IError } from "stores/types/errorTypes";
import {
  qrisAction,
  qrisInputUrl,
  qrisPay,
  qrisType,
} from "stores/types/qrisType";
import { errorGlobal } from "./errorAction";
import { constant } from "constants/config";
import config from "constants/config";

export const qrisPending = (loading: boolean): qrisAction => ({
  type: qrisType.QRIS_PENDING,
  payload: {
    isLoading: loading,
  },
});

export const qrisSuccess = (data: any): qrisAction => ({
  type: qrisType.QRIS_SUCCESS,
  payload: { ...data },
});

export const qrisError = (error: AxiosError): qrisAction => ({
  type: qrisType.QRIS_FAIL,
  payload: {
    error,
  },
});

export const qrisPendingPay = (loading: boolean): qrisAction => ({
  type: qrisType.QRIS_PAY_PENDING,
  payload: {
    isLoadingPay: loading,
  },
});

export const qrisSuccessPay = (data: any): qrisAction => ({
  type: qrisType.QRIS_PAY_SUCCESS,
  payload: { ...data },
});

export const qrisErrorPay = (data: any): qrisAction => ({
  type: qrisType.QRIS_PAY_FAIL,
  payload: { ...data },
});

export const postInputUrlQris = (
  bodyData: qrisInputUrl,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, qrisAction | IError> => {
  return async (dispatch) => {
    dispatch(qrisPending(true));
    try {
      console.log(bodyData);
      const objProvider: IProvider = {
        method: MethodProvider.POST,
        path: `${config?.API_URL + constant?.URL_QRIS_SIMULATOR_POST}`,
        data: bodyData,
      };
      const response = await provider(objProvider);
      dispatch(
        qrisSuccess({
          dataDetail: response?.data?.data,
        })
      );
      callback(response?.data);
    } catch (e) {
      callback(e?.response?.data);
      dispatch(errorGlobal(e));
      dispatch(qrisError(e));
    }
  };
};

export const payQris = (
  bodyData: qrisPay,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, qrisAction | IError> => {
  return async (dispatch) => {
    dispatch(qrisPendingPay(true));
    try {
      const objProvider: IProvider = {
        method: MethodProvider.POST,
        path: `${config?.API_URL + constant?.URL_QRIS_SIMULATOR_PAY}`,
        data: bodyData,
      };
      const response = await provider(objProvider);
      dispatch(
        qrisSuccessPay({
          ...response?.data,
        })
      );
      callback(response?.data);
    } catch (e) {
      callback(e?.response?.data);
      dispatch(errorGlobal(e));
      dispatch(qrisErrorPay(e));
    }
  };
};

export const getIssuerList = (
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, qrisAction | IError> => {
  return async (dispatch) => {
    // dispatch(qrisPending(true));
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: `${config?.API_URL + constant?.URL_QRIS_LIST_ISSUER}`,
      };
      const response = await provider(objProvider);

      // dispatch(getPaymentListSuccess(data));
      callback(response?.data);
    } catch (e) {
      callback(e?.response?.data);
      dispatch(errorGlobal(e));
    }
  };
};

export const postQrisStatic = (
  bodyData: qrisInputUrl,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, qrisAction | IError> => {
  return async (dispatch, getState) => {
    let token = getState().simulator.token;
    dispatch(qrisPending(true));
    try {
      console.log(bodyData);
      const objProvider: IProvider = {
        method: MethodProvider.POST,
        path: `${config?.API_URL + constant?.URL_QRIS_SIMULATOR_POST_STATIC}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: bodyData,
      };
      const response = await provider(objProvider);
      dispatch(
        qrisSuccess({
          dataDetailStatic: response?.data?.data,
        })
      );
      callback(response?.data);
    } catch (e) {
      callback(e?.response?.data);
      dispatch(errorGlobal(e));
      dispatch(qrisError(e));
    }
  };
};

export const payQrisStatic = (
  bodyData: qrisPay,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, qrisAction | IError> => {
  return async (dispatch, getState) => {
    let token = getState().simulator.token;
    dispatch(qrisPendingPay(true));
    try {
      const objProvider: IProvider = {
        method: MethodProvider.POST,
        path: `${config?.API_URL + constant?.URL_QRIS_SIMULATOR_PAY_STATIC}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: bodyData,
      };
      const response = await provider(objProvider);
      dispatch(
        qrisSuccessPay({
          ...response?.data,
        })
      );
      callback(response?.data);
    } catch (e) {
      callback(e?.response?.data);
      dispatch(errorGlobal(e));
      dispatch(qrisErrorPay(e));
    }
  };
};
