import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSimulator from 'hooks/useSimulator';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { setPcUrlAction } from 'stores/actions/simulatorActions';
import { useDispatch } from 'react-redux';
import { IResponse } from 'stores/types/simulatorType';
import Swal from 'sweetalert2';
import { FullVersion, isFullVersion } from 'utils/exceptions';

const Component: React.FC = () => {
  const navigate = useNavigate();
  let { simulatorState, getPaymentList, setPageId, setToken } = useSimulator();

  let { paymentList } = simulatorState;

  const dispatch = useDispatch();

  let { id } = useParams();

  React.useEffect(() => {
    !id &&
      getPaymentList({ full: FullVersion }, (response: IResponse) => {
        if (response.status_code !== 200) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Terjadi kesalahan',
            timer: 1500,
            showConfirmButton: false,
          });
        }
      });
    if (id) {
      setToken(id);
      setTimeout(() => {
        window.location.href = `${isFullVersion}`;
      }, 100);
    } // eslint-disable-next-line
  }, [getPaymentList]);

  const handleNavigate = (code: string, name: string, data: any) => {
    setPageId(data.pcPageId);
    code.includes('btransfer') && navigate(`vasimulator/${data.pcName}`);
    code.includes('emoney') &&
      dispatch(setPcUrlAction(data.pcUrl)) &&
      navigate(`walletDownload/${data.pcName}`);
    code.includes('qris') &&
      // dispatch(setPcUrlAction(data.pcUrl)) &&
      navigate(`qris`);
  };

  return (
    <div>
      <div className="text-3xl font-bold pb-2">Payment Simulator</div>
      <div>Choose payment method to get started.</div>
      <br />
      <div className="bg-[#FFFFFF] px-8 py-3 rounded-md">
        {paymentList.map((e, i) => {
          return (
            <div key={e.pmCode}>
              <div className="cursor-pointer ml-3 mt-2 flex align-center text-md">
                <div className="mr-3 font-bold text-xl py-3">
                  {e.pmChannels !== null && e.pmName}
                </div>
              </div>
              <div className="lg:ml-8 grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {e.pmChannels !== null &&
                  e.pmChannels.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="flex lg:mt-2 hover:border-[#21AF7D] border-[#CBD5E1] border-2 p-5 items-center rounded-lg justify-between  cursor-pointer align-center"
                        onClick={() => handleNavigate(e.pmCode, e.pmName, data)}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-[30px] mr-3 h-[auto]"
                            src={data?.pcPict}
                            alt={data?.pcName}
                          />
                          <div className="mr-2 text-[16px] font-semibold">
                            {data?.pcName}
                          </div>
                        </div>
                        <KeyboardArrowRightIcon sx={{ color: '#21AF7D' }} />
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
        {/* <div>
          <div className="cursor-pointer ml-3 mt-2 flex align-center text-md">
            <div className="mr-3 font-bold text-xl py-3">
              QRIS
            </div>
          </div>
          <div className="lg:ml-8 grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div
              className="flex lg:mt-2 hover:border-[#21AF7D] border-[#CBD5E1] border-2 p-5 items-center rounded-lg justify-between  cursor-pointer align-center"
              onClick={() => handleNavigate("qris", "qris", {})}
            >
              <div className="flex items-center">
                <img
                  className="w-[30px] mr-3 h-[auto]"
                  src={"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjhvTtjN1Bj37W3jTiire9jlqgP046Je6-JPvIVEMjW6avji3kH1eC5HyUDIY8q1l6z89kidy_XZz4cX7-d_rdSentSrY94naUFcRo-NhiEvMUWmevEbQz-xRdMLUFSr61dHVvbVDq58GmxM0UAIgwnfCak8KWr0wTa0UmmjdUQTTcm2pEd3YjuHtPj9Q/s2161/Logo%20QRIS.png"}
                  alt={"qris"}
                />
                <div className="mr-2 text-[16px] font-semibold">
                  QRIS
                </div>
              </div>
              <KeyboardArrowRightIcon sx={{ color: '#21AF7D' }} />
            </div>
          </div>
        </div> */}
        <br />
      </div>
    </div>
  );
};

export default Component;
