import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

export const handleGetIdmKey = (keyname: string, data: any, exps?: any) => {
  // to make sure if the data is a formdata
  let formData = {};
  if (data instanceof FormData) {
    data.forEach((val, key) => {
      // if the data is a File, normally will be format object, and in case its undefined will be null
      if (typeof val !== "object" && val !== "null") {
        formData[key] = val;
      }
    });

    // sorting the keys (in case while appending didn't sorted)
    let sortedKey = Object.keys(formData).sort();
    let sorted = sortedKey?.reduce((accumulator, key) => {
      accumulator[key] = formData[key];
      return accumulator;
    }, {});

    // re assign data as formdata after removal of file
    data = sorted;
  }

  const dataString = JSON.stringify(data);

  const dataHash = CryptoJS.MD5(dataString).toString();
  const savedIdmKey = Cookies.get(`idmKey-${keyname}`);
  const idmKey = dataHash;

  if (savedIdmKey) {
    return savedIdmKey;
  } else {
    const now = new Date();
    const expires = exps
      ? new Date(now.getTime() + Number(`${exps}000`))
      : new Date(now.getTime() + 12000); // 12 detik = 12000 milidetik

    Cookies.set(`idmKey-${keyname}`, idmKey.toString(), {
      expires,
    });

    return idmKey;
  }
};
