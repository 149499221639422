import { AxiosError } from "axios";

export enum simulatorType {
  SET_DATA_PENDING = "SET_DATA_PENDING",
  GET_DATA_SUCCESS = "GET_DATA_SUCCESS",
  SET_DATA_ERROR = "SET_DATA_ERROR",

  SET_PAGE_ID = "SET_PAGE_ID",

  SET_PAYMENTLIST_PENDING = "SET_PAYMENTLIST_PENDING",
  GET_PAYMENTLIST_SUCCESS = "GET_PAYMENTLIST_SUCCESS",
  SET_PAYMENTLIST_ERROR = "SET_PAYMENTLIST_ERROR",

  POST_DATA_SUCCESS = "POST_DATA_SUCCESS",
  SET_TOKEN = "SET_TOKEN",

  SET_PCURL = "SET_PCURL",
}

export interface ISimulatorState {
  data: IData;
  PageId: number;
  pcUrl: string;
  paymentList: PaymentMethod[];
  isLoading: boolean;
  isLoadingPaymentList: boolean;
  token: string;
  error: AxiosError;
}

interface PmChannel {
  pcId: number;
  pmId: number;
  pcCode: string;
  pcName: string;
  pcPict: string;
  pcPageId: number;
  pId?: number;
  pcLosDay?: number;
  pcIsRefund?: boolean;
  pcMaxAllowedRefundDay?: number;
}

interface PaymentMethod {
  pmChannels: PmChannel[];
  pmCode: string;
  pmId: number;
  pmName: string;
  pmPict: string;
  pmStatus: number;
}

export interface IData {
  amount: number;
  orderNumber: string;
  status_text: string;
  customer_name: string;
  customer_phone: string;
  customer_email: string;
}

interface getPaymentList {
  type: simulatorType.GET_PAYMENTLIST_SUCCESS;
  payload: {
    paymentList: any;
  };
}

interface getDataVA {
  type: simulatorType.GET_DATA_SUCCESS;
  payload: {
    data: IData;
  };
}

interface setPageId {
  type: simulatorType.SET_PAGE_ID;
  payload: {
    PageId: number;
  };
}

interface setPcUrl {
  type: simulatorType.SET_PCURL;
  payload: {
    pcUrl: string;
  };
}

interface postDataVA {
  type: simulatorType.POST_DATA_SUCCESS;
  payload?: any;
}

interface SetLoadingGet {
  type: simulatorType.SET_DATA_PENDING;
  payload?: any;
}

interface SetLoadingPaymentList {
  type: simulatorType.SET_PAYMENTLIST_PENDING;
  payload?: any;
}

interface SetLoadingPost {
  type: simulatorType.POST_DATA_SUCCESS;
  payload?: any;
}

interface SetErrorAction {
  type: simulatorType.SET_DATA_ERROR;
  payload: {
    error: AxiosError;
  };
}

interface SetErrorPaymentList {
  type: simulatorType.SET_PAYMENTLIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

interface SetToken {
  type: simulatorType.SET_TOKEN;
  payload: {
    token: string;
  };
}

export interface IResponse {
  data: Object;
  code?: number;
  status: string;
  message?: string;
  status_code: number;
}

export interface IParams {
  VaNumber: string;
  PageId: number;
}

export interface IBodyData {
  VaNumber: string;
  Amount: number;
  PageId: number;
}

export type simulatorAction =
  | getDataVA
  | SetLoadingGet
  | SetErrorAction
  | SetLoadingPost
  | postDataVA
  | SetErrorPaymentList
  | getPaymentList
  | SetLoadingPaymentList
  | setPcUrl
  | SetToken
  | setPageId;
