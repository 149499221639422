import { createTheme } from "@mui/material";
import useSimulator from "hooks/useSimulator";
import React from "react";
import { useParams } from "react-router-dom";
import { IBodyData, IParams, IResponse } from "stores/types/simulatorType";
import Swal from "sweetalert2";

export const BankContext = React.createContext(null);

interface Props {
  children: React.ReactNode;
}

const BankProvider: React.FC<Props> = ({ children }) => {
  let { vaname } = useParams();
  let { getData, postData, simulatorState } = useSimulator();
  let { PageId } = simulatorState;

  const [VaNumber, setVaNumber] = React.useState("");
  const [isDetail, setisDetail] = React.useState(false);
  const [isErrorGetDetail, setisErrorGetDetail] = React.useState(false);
  const [amount, setAmount] = React.useState(0);

  const btnTheme = createTheme({
    palette: {
      primary: {
        main: "#21AF7D",
        darker: "#053e85",
        contrastText: "#fff",
      },
      secondary: {
        main: "#EBECED",
        contrastText: "#222222",
        darker: "#053e85",
      },
    },
  });

  const handleChange = (e: any) => {
    setVaNumber(e.target.value);
    setisErrorGetDetail(false);
  };

  const handleSubmit = () => {
    let params: IParams = {
      VaNumber,
      PageId,
    };
    getData(params, (response: IResponse) => {
      if (response.status_code !== 200) {
        setisErrorGetDetail(true);
      } else {
        setisDetail(true);
      }
    });
  };

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const input = e.target.value.replace(/\D/g, ""); // Hapus semua karakter selain angka dari input
    const number = parseInt(input); // Konversi input menjadi angka
    setAmount(number);
  };

  const formattedAmount = amount?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const handlePay = () => {
    let bodyData: IBodyData = {
      VaNumber,
      Amount: amount,
      PageId,
    };

    postData(bodyData, (response: IResponse) => {
      if (response.status_code !== 200) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan",
          timer: 1500,
          showConfirmButton: false,
        });
      } else {
        handleSubmit();
        Swal.fire({
          icon: "success",
          title: "success",
          text: "succes",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    });
  };

  let valueContext = {
    handleSubmit,
    handleChange,
    isErrorGetDetail,
    VaNumber,
    vaname,
    isDetail,
    btnTheme,
    setisDetail,
    handlePay,
    handleChangeAmount,
    formattedAmount,
  };

  return (
    <BankContext.Provider value={valueContext}>{children}</BankContext.Provider>
  );
};

export default BankProvider;
