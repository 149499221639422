import { combineReducers } from "redux";
import simulatorReducer from "./simulatorReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import qrisReducer from "./qrisReducer";

export const rootReducer = combineReducers({
  // simulator: simulatorReducer,
  simulator: persistReducer(
    {
      key: "auth",
      storage,
      blacklist: ["error"],
      whitelist: ["token", "PageId"],
    },
    simulatorReducer
  ),
  qris: persistReducer(
    {
      key: "qris",
      storage,
      blacklist: ["error"],
      whitelist: ["dataDetail"],
    },
    qrisReducer
  ),
});
