import React, { useContext, useEffect, useMemo, useState } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ThemeProvider } from '@emotion/react';
import LoadingButton from '@mui/lab/LoadingButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from "react-router-dom";
import useQrisSimulatorHooks from "hooks/qrisSimulator";
import { qrisInputUrl } from "stores/types/qrisType";
import useSimulator from "hooks/useSimulator";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const QRISSimulator: React.FC = () => {
    const navigate = useNavigate()
    const [urlQR, setUrlQR] = useState<string>("")
    const [isError, setIsError] = useState<boolean>(false)
    const [errorToken, setErrorToken] = useState<boolean>(false)
    const { qrisSimulatorState, postDetailQris, } = useQrisSimulatorHooks()
    const { simulatorState } = useSimulator()


    const handleSubmit = () => {
        const data: qrisInputUrl = {
            OrderNo: urlQR,
            PageId: simulatorState.PageId
        }

        postDetailQris(data, (response: any) => {
            if (response.status_code === 200) {
                setIsError(false)
                navigate("/qris/payment")
            } else {
                setIsError(true)
            }
        })
    }

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsError(false)
            }, 3000);
        }
    }, [isError])

    const navigateStatic = () => {

        if (simulatorState.token && simulatorState.token !== "login") {
            setErrorToken(false)
            navigate("/qris/static/payment")
            navigate("/qris/static/payment")
        } else {
            setErrorToken(true)
        }
    }


    return <>
        <div className="h-full">
            <div className="flex items-center pb-3 mb-3">
                <ArrowBackIcon
                    className="cursor-pointer"
                    onClick={() => navigate("/")}
                />
                <div className="text-2xl font-bold ml-3">QRIS Simulator</div>
            </div>
            <br />
            <div className="bg-[#FFFFFF] sm:flex sm:gap-[50px] w-full px-5 py-5 lg:px-8 lg:py-3 rounded-md">
                <div className="w-full sm:w-[50%]">
                    <div className="font-bold text-lg py-3">Inquiry Request</div>
                    <div className="w-full justify-start mt-5 ">
                        <div className="flex flex-col  sm:flex-row w-full gap-[20px] items-center">
                            <div className="text-lg w-full sm:w-[30%] font-semibold items-center">
                                {/* QR Code Image Url */}
                                Transaction Number
                            </div>
                            <input
                                className="outline-none mt-2 lg:mt-0 bg-white w-full sm:w-[70%] border-solid border-2 border-[#CBD5E1] border-inherit p-[10px] rounded-md"
                                value={urlQR}
                                placeholder="Input number here"
                                onChange={(e) => setUrlQR(e.target.value)}
                            />
                        </div>
                        <div className="w-full mt-5 gap-2 flex flex-col">
                            {isError && (
                                <div className="text-[#EE4E2B] flex items-center">
                                    <InfoOutlinedIcon className="mr-2" />
                                    Transaction does not exist.
                                </div>
                            )}

                            <div className="items-end flex justify-end">
                                <LoadingButton
                                    variant="contained"
                                    sx={{ height: 40, width: '111px', background: "#21AF7D" }}
                                    color="success"
                                    loadingPosition="start"
                                    loading={qrisSimulatorState.isLoading}
                                    onClick={handleSubmit}
                                    disabled={!urlQR}
                                >
                                    Submit
                                </LoadingButton>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="w-full sm:w-[50%] flex flex-wrap gap-[30px] items-center justify-center ">
                    <Button variant="contained" color="success" sx={{ height: 40, width: '130px', background: "#21AF7D" }}
                        onClick={() => navigateStatic()}
                    >
                        QRIS Static
                    </Button>

                    {/* <ArrowForwardIcon
                        className="cursor-pointer"
                        onClick={() => navigateStatic()} /> */}
                    {errorToken && (
                        <div className="text-[#EE4E2B] flex items-center">
                            <InfoOutlinedIcon className="mr-2" />
                            Unauthorized, token not found
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
}


export default QRISSimulator