/* eslint-disable import/no-anonymous-default-export */

import {
  simulatorAction,
  ISimulatorState,
  simulatorType,
} from "stores/types/simulatorType";

const initialState = {
  data: {
    amount: 0,
    orderNumber: "",
    status_text: "",
    customer_name: "",
    customer_phone: "",
    customer_email: "",
  },
  PageId: 0,
  pcUrl: "",
  paymentList: [],
  isLoadingPaymentList: false,
  isLoading: false,
  token: "",
  error: null,
};

export default (
  state: ISimulatorState = initialState,
  { type, payload }: simulatorAction
) => {
  switch (type) {
    case simulatorType.SET_DATA_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case simulatorType.SET_PAYMENTLIST_PENDING:
      return { ...state, ...payload, error: null, isLoadingPaymentList: true };
    case simulatorType.GET_DATA_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case simulatorType.GET_PAYMENTLIST_SUCCESS:
      return { ...state, ...payload, isLoadingPaymentList: false };
    case simulatorType.POST_DATA_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case simulatorType.SET_PAGE_ID:
      return { ...state, ...payload };
    case simulatorType.SET_DATA_ERROR:
      return { ...state, ...payload, isLoading: false };
    case simulatorType.SET_PAYMENTLIST_ERROR:
      return { ...state, ...payload, isLoading: false };
    case simulatorType.SET_PCURL:
      return { ...state, ...payload, isLoading: false };
    case simulatorType.SET_TOKEN:
      return { ...state, ...payload, isLoading: false };

    default:
      return state;
  }
};
