import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainLayout from 'layout';
import Webview from 'pages/webiew';
import BankTransferComponents from 'pages/simulator/bankTransfer';
import EwalletSimulator from 'pages/simulator/ewallet';
import QrisSimulator from 'pages/simulator/qris';
import QrisPayment from 'pages/simulator/qris/payment';
import QrisStaticPayment from 'pages/simulator/qris/static/payment';
import Home from 'pages/Home';
import { isFullVersion } from 'utils/exceptions';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<p>Loading...</p>}>
        <Routes>
          <Route
            path={`${isFullVersion}:id?`}
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            }
          ></Route>
          <Route
            path={`${isFullVersion}vasimulator/:vaname`}
            element={
              <MainLayout>
                <BankTransferComponents />
              </MainLayout>
            }
          />
          <Route
            path={`${isFullVersion}walletDownload/:eWname`}
            element={
              <MainLayout>
                <EwalletSimulator />
              </MainLayout>
            }
          />
          <Route
            path={`${isFullVersion}qris`}
            element={
              <MainLayout>
                <QrisSimulator />
              </MainLayout>
            }
          />
          <Route
            path={`${isFullVersion}qris/payment`}
            element={
              <MainLayout>
                <QrisPayment />
              </MainLayout>
            }
          />
          <Route
            path={`${isFullVersion}qris/static/payment`}
            element={
              <MainLayout>
                <QrisStaticPayment />
              </MainLayout>
            }
          />
          <Route path={`${isFullVersion}web`} element={<Webview />} />
          <Route
            path={`${isFullVersion}web/:orderNumber`}
            element={<Webview />}
          />
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
