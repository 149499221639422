import { LoadingButton } from "@mui/lab"
import { Autocomplete, Button, FormControl, MenuItem, Select, TextField } from "@mui/material"
import useQrisSimulatorHooks from "hooks/qrisSimulator"
import useSimulator from "hooks/useSimulator"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { qrisPay } from "stores/types/qrisType"

const QrisPayment = () => {
    const navigate = useNavigate()
    const [issuer, setIssuer] = useState<any>(null)
    const [listIssuer, setListIssuer] = useState([])
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState("")
    const { qrisSimulatorState, postPayQris, getIssuerList } = useQrisSimulatorHooks()
    const { simulatorState } = useSimulator()

    const { dataDetail } = qrisSimulatorState

    const handlePay = () => {
        const data: qrisPay = {
            Amount: dataDetail.amount,
            IssuerCode: issuer && issuer?.code,
            OrderNo: dataDetail.number,
            PageId: simulatorState.PageId
        }
        postPayQris(data, (response: any) => {
            if (response.status_code === 200) {
                setIsSuccess(true)
                setIsError(false)
                navigate("/qris/payment")
            } else {
                setIsError(true)
                setErrMsg(response?.message)
            }
        })
    }

    useEffect(() => {
        getIssuerList((response: any) => {
            if (response && response.status_code === 200) {
                setListIssuer(response?.data)
            }
        })
    }, [])



    if (isSuccess) {
        return <>
            <div className="text-md font-bold">
                Transaction is <span className="text-[#21AF7D]">successful</span>.
            </div>
            <button onClick={() => navigate("/qris")} className="w-[220px] h-[40px] text-[#fff] bg-[#27a168] mt-4 rounded-[8px] hover:bg-[#21AF7D]">
                Simulate Another QRIS
            </button>
        </>
    }

    if (isError) {
        return <>
            <div className="text-md font-bold ">
                Transaction is <span className="text-[#EE4E2B]">failed</span>.
            </div>
            <div className="text-md font-bold mt-2">
                {/* {errMsg} */}
            </div>
            <button onClick={() => navigate("/qris")} className="w-[220px] h-[40px] text-[#fff] bg-[#27a168] mt-4 rounded-[8px] hover:bg-[#21AF7D]">
                Simulate Another QRIS
            </button>
        </>
    }


    return <>
        <div>
            <div className="text-sm font-normal">
                Customer Name
            </div>
            <div className="text-md font-semibold">
                {dataDetail?.customer_name}
            </div>
            <div className="text-sm font-normal mt-5">
                Order No
            </div>
            <div className="text-md font-semibold">
                {dataDetail?.number}
            </div>
            <div className="text-sm font-normal mt-5">
                Amount
            </div>
            <div className="text-md font-semibold">
                {dataDetail?.amount}
            </div>
            <div className="text-sm font-normal mt-5">
                Issuer
            </div>
            <div className="w-[320px] mt-2">
                <FormControl fullWidth size="small">
                    <Autocomplete
                        value={issuer}
                        onChange={(event: any, newValue: string | null) => {
                            setIssuer(newValue);
                        }}
                        // inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     setInputValue(newInputValue);
                        // }}
                        id="controllable-states-demo"
                        options={listIssuer}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Issuer" />}
                    />
                    {/* <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={issuer}
                        // label="Age"
                        onChange={(e) => setIssuer(e.target.value)}
                    >
                        <MenuItem value={"none"} disabled>Select Issuer</MenuItem>
                        {listIssuer.length > 0 && listIssuer.map((x, i) => (
                            <MenuItem key={x.id} value={x.code}>{x.name}</MenuItem>
                        ))}
                    </Select> */}
                </FormControl>
            </div>
            <div className="flex mt-5 justify-start">
                <LoadingButton
                    variant="contained"
                    sx={{ height: 40, width: '111px', background: "#21AF7D" }}
                    color="success"
                    loadingPosition="start"
                    loading={qrisSimulatorState.isLoadingPay}
                    onClick={handlePay}
                    disabled={!issuer}
                >
                    Pay
                </LoadingButton>
            </div>
        </div>
    </>
}

export default QrisPayment