import { AxiosError } from "axios";

export enum qrisType {
  QRIS_PENDING = "QRIS_PENDING",
  QRIS_SUCCESS = "QRIS_SUCCESS",
  QRIS_FAIL = "QRIS_FAIL",

  QRIS_PAY_PENDING = "QRIS_PAY_PENDING",
  QRIS_PAY_SUCCESS = "QRIS_PAY_SUCCESS",
  QRIS_PAY_FAIL = "QRIS_PAY_FAIL",
}

export interface qrisInputUrl {
  OrderNo?: string;
  PageId: number;
}

export interface qrisDetail {
  amount: number;
  customer_email: string;
  customer_name: string;
  customer_phone: string;
  number: string;
  qr_value: string;
  status: number;
  status_text: string;
}

export interface qrisPay {
  OrderNo?: string;
  PageId: number;
  IssuerCode: string;
  Amount: number;
}

export interface qrisStaticDetail {
  mq_id: number;
  m_id: number;
  mq_merchant_id: string;
  mq_merchant_code: string;
  mq_merchant_name: string;
  mq_nmid: string;
  mq_qoin_id: string;
  mq_owner_name: string;
  mq_account_no: string;
  mq_identity_type: number;
  mq_identity_no: string;
  mq_criteria: string;
  mq_category: number;
  mq_business_address: string;
  mq_country_id: string;
  mq_province: string;
  mq_city: string;
  mq_subdistrict: string;
  mq_postal_code: string;
  mq_type_qr: number;
  mq_npwp: string;
  mq_phone_number: string;
  mq_status: number;
  terminal: Terminal[];
  mq_merchant_qris_type: string;
  mq_type_tips: number;
  mq_tip_static: number;
  mq_tip_procentage: number;
  mq_business_type: number;
  mq_create_date: Date;
  mq_created_by: number;
  mq_updated_date: Date;
  p_id: number;
  mq_mpan: string;
}

export interface Terminal {
  mqt_id: number;
  mq_id: number;
  mqt_name: string;
  mqt_terminal_id: string;
  mqt_phone: string;
  mqt_email: string;
  mqt_dynamic_client_id: string;
  mqt_dynamic_client_secret: string;
}

export interface qrisState {
  isLoading: boolean;
  isLoadingPay: boolean;
  dataDetail: qrisDetail | null;
  dataDetailStatic: qrisStaticDetail | null;
  error: AxiosError;
  errorPay: AxiosError;
}

interface SetLoadingQris {
  type: qrisType.QRIS_PENDING;
  payload: any;
}

interface SetSuccessDetailQris {
  type: qrisType.QRIS_SUCCESS;
  payload: {
    dataDetail: qrisDetail;
  };
}

interface SetErrorQris {
  type: qrisType.QRIS_FAIL;
  payload: {
    error: AxiosError;
  };
}

interface SetLoadingPayQris {
  type: qrisType.QRIS_PAY_PENDING;
  payload: any;
}

interface SetSuccessPayQris {
  type: qrisType.QRIS_PAY_SUCCESS;
  payload: any;
}

interface SetErrorPayQris {
  type: qrisType.QRIS_PAY_FAIL;
  payload: {
    errorPay: AxiosError;
  };
}

export type qrisAction =
  | SetLoadingQris
  | SetSuccessDetailQris
  | SetErrorQris
  | SetLoadingPayQris
  | SetSuccessPayQris
  | SetErrorPayQris;
