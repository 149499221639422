import React from "react";
import Navbar from "./components/navbar";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import useSimulator from "hooks/useSimulator";

type Props = {
  children: React.ReactNode;
};

const Component: React.FC<Props> = ({ children }) => {
  let { isLoading } = useSimulator().simulatorState;
  return (
    <>
      <BlockUi tag="div" blocking={isLoading}>
        <Navbar />
        <div className="bg-[#FED372] w-full p-3 text-center">
          Currently you’re at simulator mode!
        </div>
        <div className="px-[20px] py-[30px] lg:px-[80px] lg:py-[40px]">
          {children}
        </div>
      </BlockUi>
    </>
  );
};

export default Component;
